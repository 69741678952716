import React from "react";
import { Link } from "gatsby";

const getInternalLink = ({ children, mark }) => {
  const path = mark?.page && getPath(mark.page);
  if (path) {
    return <Link to={path}>{children}</Link>;
  }
  return <>{children}</>;
};

const getPath = node => {
  let path;
  const slug = node?.slug?.current;

  switch (node._type) {
    case "page":
      path = `/${slug}`;
      break;
    case "news":
      path = `/news/${slug}`;
      break;
    case "person":
      path = `/people/${slug}`;
      break;
    case "service":
      path = `/expertise/${slug}`;
      break;
    default:
      break;
  }

  return path;
};

export default getInternalLink;
